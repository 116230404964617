
  <div class="fluid">
    <div class="title text-right mt-4">
      Current network: {{ currentNetwork }}
    </div>
    <div class="balances">
      <div class="text-xl mb-4">
        Faucet
        <a class="text-blue-500" target="_blank" href="https://solfaucet.com/">
          https://solfaucet.com/
        </a>
      </div>
      <div class="title">Account balances</div>
      <div v-if="account" class="items">
        <div v-for="item in balancesList" :key="item.mintAddress" class="item">
          <div>
            <div>
              <span class="mr-1">Mint Address:</span>
              <span>
                {{ middleEllipsis(item.mintAddress) }}
              </span>
            </div>
            <div>
              <span class="mr-1">Token Account:</span>
              <span>
                {{ middleEllipsis(item.tokenAccountAddress) }}
              </span>
            </div>
          </div>
          <div class="text-right">
            <div>Balance</div>
            <div>{{ item.balance }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="methods mt-8">
      <div class="title">Stake info from mainnet-beta</div>
      <div clas="mt-4">
        Get stake info of HYf79FVs4xqUAgDDX5PgecTyToXk858UDSJTExR9J94o on
        Raydium
      </div>
      <div class="items">
        <div
          v-for="info in stakeInfos"
          :key="info.stakeAccountAddress"
          class="item"
        >
          <div>
            <span class="mr-1">Stake account address:</span>
            <span>
              {{ (info.stakeAccountAddress) }}
            </span>
          </div>
          <div class="text-right">
            <div>
              <span class="mr-1">Staked:</span>
              <span>{{ info.depositBalance }} RAY</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8">
      <el-button
        v-if="account"
        @click="sendExampleTransaction"
        class="focus:outline-none"
      >
        Send an example transaction
      </el-button>
      <div class="mt-4" v-if="txid">
        txid:
        <a
          target="_blank"
          class="text-blue-400"
          :href="`https://solscan.io/tx/${txid}?cluster=testnet`"
        >
          {{ middleEllipsis(txid) }}
        </a>
      </div>
    </div>
  </div>
