
import { defineComponent, computed, ref } from 'vue';
import {
  tokenAccountsDataMap,
  account,
  stakeInfos,
  sendTransaction,
  connection,
  walletAdapterConnected,
} from '@/hooks';
import { middleEllipsis, toDP } from '@/utils';
import { NETWORK_CLUSTER } from '@/configs';

export default defineComponent({
  setup() {
    const txid = ref<string | null>('');
    const balancesList = computed(() => {
      return Array.from(tokenAccountsDataMap.values());
    });
    const currentNetwork = computed(() => {
      return NETWORK_CLUSTER.toUpperCase();
    });
    const sendExampleTransaction = async () => {
      if (walletAdapterConnected.value && connection.value) {
        txid.value = await sendTransaction(
          walletAdapterConnected.value,
          connection.value,
        );
      }
    };
    return {
      balancesList,
      account,
      middleEllipsis,
      toDP,
      stakeInfos,
      sendExampleTransaction,
      txid,
      currentNetwork,
    };
  },
});
